import "./App.css";
import "./components/Contacts/style.css";
import Contacts from "./components/Contacts";
import {PU} from './PopUpJK';

function App() {
  return (
    <PU>
      <div className="App">
        <Contacts />
      </div>
    </PU>
    
  );
}

export default App;
