import { useState } from "react";
import Form from "./Form";
import List from "./List";
import {nanoid} from 'nanoid';
import {PUC} from '../../PopUpJK';

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [selId, setSelId] = useState("");
  const [page, setPage] = useState(0);
  const [, setP] = PUC();

  const handleAddEdit = (nc) => {
    function compare (a, b) {
      return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
    };
    const lessContacts = contacts.filter((c) => c.id !== nc.id);
    lessContacts.push(nc);
    lessContacts.sort(compare);
    setContacts(lessContacts);
  }

  const delNo= () => {};

  const delYes = (id) => {
    const lessContacts = contacts.filter((c) => c.id !== id);
    setContacts(lessContacts);
    setP({option: 0, open:true, message:'contact deleted'});
  };

  const handleDel = (id) => {
    const msg = 'Wanna delete this contact item?';
    setP({option: 1, open:true, message:msg, CBO:() => delYes(id), CBX: delNo});
  }

  const selectContact = (selId) => {
       let initial = {id:nanoid(), name: "", phone: "", email:"", remark: "" };
       if (selId === "") {
        return initial;
       } else {
        let selected = contacts.filter((c) => c.id === selId);
        return selected.length > 0 ? selected[0] : initial;
       }
  }
  const selContact = selectContact(selId);

  return (
    <div id="container">
      <div id="box">
      <
        Form 
        key={selContact.id} 
        selId={selId} 
        addEditContact={handleAddEdit} 
        setSelId={setSelId} 
        selContact={selContact} 
      />
      &nbsp;
      <
        List 
        contacts={contacts} 
        selId={selId} 
        editFunc={setSelId} 
        delFunc={handleDel} 
        page={page} 
        setPage={setPage}
        setContacts={setContacts} 
      />  
      <br /><br />
      </div>
    </div>
  );
}

export default Contacts;
