const aboutText = 
<>
This is a simple program for contact information. Check tool tips. <br />
1. Make a contact info item using the 'Create/Edit Contact' textarea.<br />
2. As you make contact info items, those items are shown below the Search bar.<br />
  They are shown after alphabetical sorting.<br />
3. When you want to edit the item, you can send it to the 'Create/Edit Contact' textarea <br />
by clicking the item's Edit button.<br />
4. A sample batch of items are provided.  Press the 'Get test data' button. <br />
5. Search can be made in terms of Name, Phone number, Email address, and Remark text.<br />
</>;

export default aboutText;

