import { useState } from "react";
import './list.css';
import testData from '../contactsData';
import About from '../About';
import { GoMoveToEnd } from "react-icons/go";
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { GoMoveToStart } from "react-icons/go";
import { GoDatabase } from "react-icons/go";
import { MdOutlineDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { LuInfo } from "react-icons/lu";
import {T, PUC} from '../../../PopUpJK';

const pageSize = 5;
var testDataIn = false;

function List({ contacts, editFunc, delFunc, selId, page, setPage, setContacts }) {
  const [filterText, setFilteringText] = useState("");
  const [category, setCategory] = useState("name");
  const [about, setAbout] = useState(false);
  const [, setP] = PUC();

  const filterData = () => {
    return contacts.filter(item => 
      item[category].toLocaleLowerCase().includes(filterText.toLocaleLowerCase()));
  };

  const filteredData = filterData();

  const numPages = Math.ceil(filteredData.length / pageSize);

  const pageData = filteredData.slice(page * pageSize, page * pageSize + pageSize);

  if (page * pageSize > filteredData.length) {
    setPage(0);
  }

  const itemContent = (item) => 
    `name:${item.name}\n phone number: ${item.phone}\nemail addr: ${item.email}\nremark: ${item.remark}`;

  const delNo= () => {};

  const delYes = () => {
    testDataIn = false;
    setContacts([]);
    setP({option: 0, open:true, message:'All contacts deleted'});
  };

  const handleDel = () => {
    const msg = 'Wanna delete all these contact items?';
    setP({option: 1, open:true, message:msg, CBO:delYes, CBX: delNo});
  }

  const getTestData = () => {
    if (testDataIn) {
      handleDel();
    } else {
      testDataIn = true;
      function compare (a, b) {
        return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
      };
      const tmp = [...testData];
      tmp.sort(compare);
      setContacts(tmp);
    }
  };

  return (
    <div>
        <h4>Contact List</h4>
      <div style={{display:'flex', justifyContent:'center'}}>
        <input
          placeholder="categoried search"
          onChange={(e) => setFilteringText(e.target.value)}
        />
        <select id='searchSelect' title='Select category' value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="0" disabled>Select category</option>
          <option value="name">name</option>
          <option value="phone">phone number</option>
          <option value="email">email address</option>
          <option value="remark">remark</option>
        </select>
      </div>

    {pageData.length > 0 &&
    <div style={{display:'flex', justifyContent:'center'}} >
      <table id="customers" style={{width:'80%',marginTop:'20px', marginBottom:'20px'}}>
        <thead>        
        <tr>
          <th style={{width:'80%'}}>name</th>
          <th style={{width:'10%'}}>Edit</th>
          <th style={{width:'10%'}}>Del</th>
        </tr>
        </thead>

        <tbody>     
        {
          pageData.map((item) => (
            <tr key={item.id}>
              <td >
                <T r={<span>{item.name}</span>} s={itemContent(item)} w={200} />
              </td>
              <td>
                < T 
                  r={<
                      button 
                      className='list-button' 
                      onClick={item.id !== selId ? () => editFunc(item.id) : ()=>{} }
                      style={item.id === selId ? {backgroundColor:'green'} : {}}
                    >
                      <FaEdit />
                    </button>}
                  s={item.id === selId ? 'edit in progress' : 'edit'}
                />
              </td>
              <td>
                < T
                  r={<button 
                      className='list-button' 
                      onClick={item.id !== selId ? () => delFunc(item.id) : ()=>{} }
                      style={item.id === selId ? {backgroundColor:'green'} : {}}
                    >
                      <MdOutlineDeleteForever />
                    </button>}
                  s={item.id !== selId ? 'delete'
                     : 'edit in progress. Delete after saving.'}
                  w={item.id !== selId ? 40 : 80}
                />
              </td>
            </tr>
          ))
        }
          </tbody>
      </table>
    </div>
    }
   
      <div style={{display:'flex', justifyContent:'center'}}>
        {
          numPages >= 2 &&
          <>
            {
              page > 1 &&
              <>
                &nbsp;
                < T 
                  r={
                      <button className='page-button'  onClick={()=>setPage(0)}>
                       <GoMoveToStart />
                     </button>
                    } 
                  s='First page' 
                  t={40} 
                />
              </>
            }
            {
              page > 0 &&
              <>
               &nbsp;
              < T 
                r={
                    <button className='page-button' onClick={()=>setPage(page=>page-1)}>
                      <GrLinkPrevious />
                    </button>
                  } 
                s='Prev page'
                t={40} 
              />
              </>
            }
             &nbsp;
             <T r={<div id='showPage'>{page}</div>} s='Current page' t={40} />
             &nbsp;
             < T 
               r={
                  <div id='showPage' style={{backgroundColor:'transparent', border:'1px white'}}>
                    {numPages}
                  </div>
                } s='Total pages' t={40} />
            {
              page < numPages - 1 &&
              <>
               &nbsp;
               < T 
                 r={
                    <button className='page-button' onClick={()=>setPage(page=>page+1)}>
                      <GrLinkNext />
                    </button>
                  } 
                 s='Next page' 
                 t={40}
                />
              </>
            }
            {
              page < numPages - 2 &&
              <>
              &nbsp;
              < T 
                r={
                    <button className='page-button' onClick={()=>setPage(numPages-1)}>
                      <GoMoveToEnd />
                    </button>
                  } 
                s='Last page' 
                t={40}
              />
              </>
            }         
          </>
        } 
        &nbsp;
        < T 
          r={<button className="about-button" onClick={getTestData}><GoDatabase /></button>} 
          s={testDataIn ? 'Remove data' : 'Get test data' }
          w={80}
          t={40}
        />
        &nbsp;
        < T 
          r={<button className="about-button" onClick={()=>setAbout(true)}><LuInfo /></button>} 
          s='About' 
          t={40}
        />
      </div>
      <About about={about} setAbout={setAbout}/>
    </div>
  );
}
export default List;
