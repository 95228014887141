import { useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { CiSaveDown2 } from "react-icons/ci";
import {PUC, T} from '../../../PopUpJK';

function Form({selId, selContact, addEditContact, setSelId }) {
  const [form, setForm] = useState(selContact);
  const [p, setP] = PUC();

  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (form.email === "" && form.phone === "") {
      const msg = "Please fill in email or/and phone number!";
      setP({...p, option: 0, open:true, message:msg});
      return;
    }
    addEditContact(form); 
    setSelId("");  
    const msg = selId === '' ? 'One contact created.' : 'One contact saved after editting.';
    setP({...p, option: 0, open:true, message:msg});
  };

  return (
    <form onSubmit={onSubmitForm}>
      <div style={{display:'flex', justifyContent:'center'}}>
      <h4>Create/Edit Contact</h4>
      </div>
      <div>
        <input
          onChange={onChangeInput}
          value={form.name}
          name="name"
          type="text"
          placeholder="Full Name"
          required
        />
      </div>
      <div>
        <input
          onChange={onChangeInput}
          value={form.phone}
          name="phone"
          type="tel"
          placeholder="Phone Number"
          maxLength="10"
        />
      </div>
      <div>
        <input
          onChange={onChangeInput}
          value={form.email}
          name="email"
          type="email"
          placeholder="Email Address"
          size="30" 
        />
      </div>
      <div>
      <textarea  
        value={form.remark} 
        onChange={onChangeInput} 
        name="remark" 
        rows="3" 
        cols="52" 
        placeholder="Remark" 
      >
      </textarea>
      </div>
      <div id="button" style={{display:'flex', justifyContent:'center'}}>
        <T 
          r={
            <button className='specialButton' >
              {selId === '' ? <IoMdAddCircleOutline /> : <CiSaveDown2 />}
            </button>
          }
         s={selId === '' ? 'Add' : 'Save'}
         t={40}
        />
        
      </div>
    </form>
  );
}
//        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
//          pattern=".+@example\.com" 
export default Form;
