import {nanoid} from 'nanoid';

const testData = [
    {id:nanoid(), name: "Jinkyu Kim", phone: "123-456-7890", email:"jkim@test.com", remark: "fake me" },
    {id:nanoid(), name: "John Smith", phone: "565-173-7777", email:"jsmithest@gmail.com", remark: "associate" },
    {id:nanoid(), name: "Robert Dale", phone: "902-678-0922", email:"rdale@hotmail.com", remark: "coworker" },
    {id:nanoid(), name: "Amy Smith", phone: "431-777-1111", email:"asmith@outlook.com", remark: "friend" },
    {id:nanoid(), name: "Jane Goodal", phone: "301-555-3333", email:"jg@gmail.com", remark: "not real J G" },
    {id:nanoid(), name: "George Washington", phone: "123-456-7788", email:"gw@mail.com", remark: "not real G.W." },
   
];

export default testData;